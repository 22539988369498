import React from 'react';
import { InputAdornment } from '@material-ui/core';

const labelClasses = ({ value, errors }) => {
	if (value && !errors) return 'cssLabel';
	else {
		if ((!value && errors) || errors) return 'cssLabelError';
		else return 'cssLabelSuccess';
	}
};

const notchedOutlineClasses = ({ value, errors }) => {
	if (value && !errors) return 'cssInput';
	else {
		if ((!value && errors) || errors) return 'cssInputError';
		else return 'cssInputSuccess';
	}
};

const bothInputProps = ({ type = '', value, errors, prefix = false }) => ({
	InputLabelProps: {
		classes: {
			root: labelClasses({ errors, value })
		},
		shrink: true
	},
	InputProps: {
		classes: {
			notchedOutline: notchedOutlineClasses({
				errors,
				value
			})
		},
		startAdornment:
			prefix && type === 'text' ? (
				<InputAdornment position="start">{prefix}</InputAdornment>
			) : null
	}
});
export { notchedOutlineClasses, labelClasses, bothInputProps };
