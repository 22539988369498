import React, { Component } from 'react';
import {
	TextField,
	Button,
	InputAdornment,
	IconButton
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';

class FileWithIconInput extends Component {
	bothInputProps = (value, error, name, ifExist, onClick, showView) => ({
		InputLabelProps: {
			classes: {
				root:
					value && !error
						? 'cssLabel'
						: (!value && error) || error
						? 'cssLabelError'
						: 'cssLabelSuccess'
			}
		},
		InputProps: {
			classes: {
				// notchedOutline: 'cssInputSelect',
				notchedOutline:
					value && !error
						? 'cssInput'
						: (!value && error) || error
						? 'cssInputError'
						: 'cssInputSuccess'
			},
			endAdornment: (
				<InputAdornment position="end">
					{ifExist === true && showView === true && (
						<IconButton
							onClick={onClick}
							className="button-file-inside"
							aria-label="select file"
						>
							<VisibilityIcon />
						</IconButton>
					)}
					<label htmlFor={`contained-button-${name}`}>
						<Button
							variant="contained"
							component="span"
							className="button-file"
							aria-label="select file"
						>
							<AttachFileIcon />
						</Button>
					</label>
				</InputAdornment>
			)
		}
	});

	render() {
		const {
			name,
			value,
			errors,
			onChange,
			label,
			required = true,
			helperText = '',
			placeholder = '',
			addClass,
			ifExist = false,
			onClick,
			labelClass = '',
			changeClass = true,
			showView = false,
			disabled = false
		} = this.props;

		const labelInput = changeClass ? (
			<span className={labelClass}>{label}</span>
		) : (
			label
		);

		return (
			<>
				<style global jsx>
					{`
						.button-file-inside {
							box-shadow: none !important;
							border-radius: 50px !important;
							margin-right: 5px !important;
						}
						.button-file-inside svg {
							color: #9aabb5 !important;
						}
						.button-file {
							border: 1px solid #e6ebf1 !important;
							border-right: 0 !important;
							background-color: #fff !important;
							min-height: 56px !important;
							padding-left: 12px !important;
							border-top-left-radius: 0 !important;
							border-bottom-left-radius: 0 !important;
							border-top-right-radius: 4px !important;
							border-bottom-right-radius: 4px !important;
							margin-right: -10px !important;
							box-shadow: none !important;
							min-width: 50px !important;
							height: 32px !important;
						}
						.button-file svg {
							color: #200844 !important;
						}
					`}
				</style>
				<TextField
					name={name}
					style={{ display: 'none' }}
					id={`contained-button-${name}`}
					type="file"
					required={required}
					onChange={onChange}
					disabled={disabled}
				/>
				<TextField
					variant="outlined"
					label={labelInput}
					// label={label}
					className={addClass}
					type="text"
					disabled={true}
					margin="normal"
					error={true}
					required={required}
					helperText={helperText}
					placeholder={placeholder}
					value={value ? value.name || value : placeholder}
					// value={value ? (value.name || value) : ''}
					{...this.bothInputProps(
						value,
						errors,
						name,
						ifExist,
						onClick,
						showView
					)}
				/>
			</>
		);
	}
}

export default FileWithIconInput;
