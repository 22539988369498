import React from 'react';

const logo = '/static/images/favicon.png';

function SpinnerV(props) {
	return (
		<div className="w-screen h-screen z-99 fixed top-0 left-0 bg-black bg-opacity-75 flex justify-center items-center flex-col">
			<style global jsx>
				{`
					.orbit-global {
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
					}

					.first-orbit {
						width: 165px;
						height: 165px;
						border: 2px solid #200844;
						-webkit-animation: spin3D 3s linear 0.2s infinite;
					}

					.second-orbit {
						width: 120px;
						height: 120px;
						border: 2px solid #fb314a;
						-webkit-animation: spin3D 2s linear 0s infinite;
					}

					.third-orbit {
						width: 90px;
						height: 90px;
						border: 2px solid #fff;
						-webkit-animation: spin3D 1s linear 0s infinite;
					}
				`}
			</style>
			<div className="p-r flex justify-center items-center m-b60">
				<div className="first-orbit orbit-global"></div>
				<div className="second-orbit orbit-global"></div>
				<div className="third-orbit orbit-global"></div>
				<img
					loading="lazy"
					src={logo}
					alt="logo asistensi"
					className="w-auto h-12"
				/>
			</div>
			{props.message && (
				<div className="flex justify-center items-center text-center m-t20 color-white strong">
					{props.message}
				</div>
			)}
		</div>
	);
}

export default SpinnerV;
