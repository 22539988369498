export default function formatPhonePH(value) {
	if (!value) return value;
	const phoneNumber = value.replace(/[^\d]/g, '');
	const phoneNumberLength = phoneNumber.length;
	if (phoneNumberLength <= 4)
		`+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 4)})`;
	if (phoneNumberLength <= 8) {
		return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
			2,
			4
		)}) ${phoneNumber.slice(4, 8)}`;
	}
	return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
		2,
		4
	)}) ${phoneNumber.slice(4, 8)}-${phoneNumber.slice(8, 12)}`;
}
