import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { bothInputProps } from '../utils/inputClasses';

const TextInput = ({
	name = '',
	value = '',
	errors = null,
	onChange = null,
	onFocus = null,
	onEnter = null,
	onBlur = null,
	error = true,
	required = true,
	label = '',
	prefix = '',
	addClass = '',
	type = 'text',
	helperText = '',
	placeholder = '',
	margin = 'normal',
	variant = 'outlined',
	multiline = false,
	rows = 2,
	labelClass = '',
	FormHelperTextProps = {},
	disabled = false,
	maxLength = null
}) => {
	const labelInput = label ? (
		<span className={labelClass}>{label}</span>
	) : (
		label
	);

	const titleTooltip = {
		title: required ? 'Complete this field' : ''
	};

	return (
		<TextField
			name={name}
			label={labelInput}
			placeholder={placeholder}
			type={type}
			helperText={helperText}
			variant={variant}
			className={addClass}
			{...bothInputProps({ errors, value, prefix, type: 'text' })}
			margin={margin}
			error={error}
			required={required}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			disabled={disabled}
			{...(onEnter && {
				onKeyPress: (ev) => {
					if (ev.key === 'Enter') onEnter();
				}
			})}
			multiline={multiline}
			minRows={rows}
			FormHelperTextProps={FormHelperTextProps}
			inputProps={
				maxLength
					? {
							maxLength,
							...titleTooltip
					  }
					: {
							...titleTooltip
					  }
			}
			onBlur={onBlur}
		/>
	);
};

TextInput.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	errors: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onEnter: PropTypes.func,
	onBlur: PropTypes.func,
	error: PropTypes.bool,
	required: PropTypes.bool,
	label: PropTypes.string,
	prefix: PropTypes.string,
	addClass: PropTypes.string,
	type: PropTypes.string,
	helperText: PropTypes.string,
	placeholder: PropTypes.string,
	margin: PropTypes.string,
	variant: PropTypes.string,
	multiline: PropTypes.bool,
	rows: PropTypes.number,
	labelClass: PropTypes.string,
	FormHelperTextProps: PropTypes.object,
	disabled: PropTypes.bool
};

export default TextInput;
