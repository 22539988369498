import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { SpinnerV, SnackBar } from 'components/widgets';
import { TextInput, CellPhoneInput } from 'components/commons';
import BaseLead from 'models/BaseLead';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import formatPhonePH from 'components/utils/formatPhonePH';

const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE_RESUME;
const DEFAULT_CODE_TLF = process.env.REACT_APP_DEFAULT_CODE_TLF;

const NotFoundLocation = ({ lead, handleClose, actions }) => {
	const [leadForm, setLeadForm] = useState(BaseLead);
	const [countryCode, setCountryCode] = useState('');
	const [formErrors, setFormErrors] = useState([]);
	const [spinnerScreen, setSpinnerScreen] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [messageAlert, setMessageAlert] = useState(false);

	useEffect(() => {
		getLocation();
		leadForm.origin = 'CIUDAD-SIN-COBERTURA';
		leadForm.city_interest = '';
		if (lead) {
			leadForm.first_name = lead?.first_name;
			leadForm.last_name = lead?.last_name;
			leadForm.email = lead?.email;
			leadForm.phone_one = lead?.phone_one;
			setLeadForm({ ...leadForm });
		}
	}, []);

	const getLocation = async () => {
		const response = await actions.getLocation();
		setCountryCode((response?.countryCode || COUNTRY_CODE).toLowerCase());
	};

	const handleInput = (event) => {
		const { name, value } = event.target;
		leadForm[name] = value;
		setLeadForm({ ...leadForm });
		validateInput(name, value);
	};

	const handlePhone = (name, value) => {
		leadForm[name] = name.includes('phone') ? formatPhonePH(value) : value;
		setLeadForm({ ...leadForm });
		validateInput(name, value);
	};

	const validateInput = (name, value) => {
		let errors = formErrors;
		let validate = false;

		switch (name) {
			case 'phone_one':
				validate = /^([0-9()+ -]{10,})$/.test(value);
				!validate
					? (errors.phone_one = `Enter a valid phone number.`)
					: delete errors.phone_one;
				break;
			case 'first_name':
				validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,._-]{3,})$/.test(value);
				!validate
					? (errors.first_name = 'Enter a valid name.')
					: delete errors.first_name;
				break;
			case 'last_name':
				validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,._-]{3,})$/.test(value);
				!validate
					? (errors.last_name = 'Enter a valid last name.')
					: delete errors.last_name;
				break;
			case 'city_interest':
				validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,._-]{2,})$/.test(value);
				!validate
					? (errors.city_interest = 'Enter a valid barangay.')
					: delete errors.city_interest;
				break;
			case 'email':
				validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
				!validate
					? (errors.email = 'Enter a valid email address.')
					: delete errors.email;
				break;
		}
		setFormErrors({ ...errors });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		Object.keys(leadForm).forEach((k) => validateInput(k, leadForm[k]));
		if (Object.keys(formErrors).length === 0) {
			setSpinnerScreen(true);
			const response = await actions.createNewLead({ ...leadForm });
			if (response) {
				setSpinnerScreen(false);
				setOpenAlert(response?.openAlert);
				setMessageAlert(response?.messageAlert);
				setTimeout(() => {
					handleClose();
				}, 3000);
			}
		} else {
			setOpenAlert(true);
			setMessageAlert('Verifique los errores y rellene los campos requeridos');
		}
	};
	const handlePhoneInput = (telNumber) => handlePhone('phone_one', telNumber);

	return (
		<div
			className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex justify-center items-center"
			style={{ zIndex: '999' }}
		>
			{spinnerScreen && <SpinnerV />}
			<span
				onClick={() => handleClose()}
				className="absolute right-1 top-0 bg-pink-500 p-3 cursor-pointer hidden sm:inline-block"
			>
				<CloseIcon className="text-white" />
			</span>
			<div className="bg-white w-full h-full py-5 px-5 overflow-y-scroll sm:px-10 sm:max-w-2xl sm:h-auto md:max-h-86">
				<div className="w-full flex justify-end sm:hidden">
					<IconButton
						onClick={() => handleClose()}
						style={{ margin: '-1rem -1rem 0 0' }}
					>
						<CloseIcon className="text-purple-500" />
					</IconButton>
				</div>
				<form
					className="w-full text-gray-800 flex-col pb-8 sm:pt-6"
					onSubmit={(e) => handleSubmit(e)}
				>
					<div className="text-3xl font-poppins font-bold mb-8">
						Leave us your information
					</div>
					<div className="w-full flex flex-wrap">
						<TextInput
							name="first_name"
							label="First Name"
							placeholder="Enter first name"
							required={true}
							helperText={formErrors.first_name}
							errors={formErrors.first_name}
							value={leadForm.first_name}
							addClass="wInputFull"
							onChange={(e) => handleInput(e)}
						/>
						<TextInput
							name="last_name"
							label="Last Name"
							placeholder="Enter last name"
							required={true}
							helperText={formErrors.last_name}
							errors={formErrors.last_name}
							value={leadForm.last_name}
							addClass="wInputFull"
							onChange={(e) => handleInput(e)}
						/>
						<TextInput
							name="email"
							type="email"
							label="Email Address"
							placeholder="Enter email address"
							required={true}
							helperText={formErrors.email}
							errors={formErrors.email}
							value={leadForm.email}
							addClass="wInputFull"
							onChange={(e) => handleInput(e)}
						/>
						<TextInput
							name="city_interest"
							label="Barangay of interest"
							placeholder="Write your barangay of interest"
							required={true}
							helperText={formErrors.city_interest}
							errors={formErrors.city_interest}
							value={leadForm.city_interest}
							addClass="wInputFull"
							onChange={(e) => handleInput(e)}
						/>
						{countryCode && (
							<CellPhoneInput
								label="Mobile number"
								addClass="wInputFull"
								country={countryCode}
								value={leadForm.phone_one}
								onChange={handlePhoneInput}
								error={formErrors.phone_one}
								required={true}
							/>
						)}
					</div>
					<div className="flex w-full mt-6 justify-center">
						<button className="primary-button min-w-full sm:min-w-0">
							Submit
						</button>
					</div>
				</form>
			</div>
			<SnackBar
				openAlert={openAlert}
				messageAlert={messageAlert}
				handleClose={() => setOpenAlert(false)}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	titular: state.affiliation.get('titular')
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundLocation);
