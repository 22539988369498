import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	MenuItem
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function SendDocumentsDialog(props) {
	const [option, setOption] = useState('default');
	const [email, setEmail] = useState('');

	const handleSelect = (event) => setOption(event.target.value);
	const handleInput = (event) => setEmail(event.target.value);

	return (
		<Dialog
			open={props.open}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
		>
			<DialogTitle>
				<span className="text-gray-800 font-bold">
					 ¿A que correo deseas enviar los documentos?
				</span>
			</DialogTitle>
			<DialogContent>
				<TextField
					select
					name="option"
					value={option}
					onChange={handleSelect}
					variant="outlined"
					required={true}
					className={`wInputFull ${option === 'default' && 'default'}`}
					InputProps={{
						classes: {
							notchedOutline: 'cssInputSelect'
						}
					}}
					SelectProps={{
						IconComponent: KeyboardArrowDownIcon
					}}
				>
					<MenuItem value="default" disabled hidden>
						Seleccione una opción
					</MenuItem>
					<MenuItem value={1}>
						Enviar correo al titular y beneficiarios
					</MenuItem>
					<MenuItem value={2}>
						Enviar documentos a un correo específico
					</MenuItem>
				</TextField>
				{option === 2 && (
					<TextField
						name="email"
						type="email"
						placeholder="Correo electrónico"
						required={true}
						value={email}
						onChange={handleInput}
						variant="outlined"
						className="wInputFull"
						InputProps={{
							classes: {
								notchedOutline: 'cssInputSelect'
							}
						}}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<button
					onClick={props.handleClose}
					className="secondary-button-nav min-w-32 mt-2"
				>
					Cancelar
				</button>
				<button
					onClick={() => props.handleSubmit(option, email)}
					disabled={option === 'default' ? true : false}
					className="white-button-nav min-w-32"
				>
					Enviar
				</button>
			</DialogActions>
		</Dialog>
	);
}

export default SendDocumentsDialog;
