import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';

const RadioInput = withStyles({
	root: {
		color: '#9AABB5',
		'&$checked': {
			color: '#fb314a'
		}
	},
	checked: {}
})((props) => <Radio {...props} />);

export default RadioInput;
