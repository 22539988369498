import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

function ContainerVideo(props) {
	return (
		<div className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 z-50 flex justify-center items-center">
			<span
				className="absolute right-1 top-0 bg-pink-500 p-3 cursor-pointer"
				onClick={() => props.handleClose(false)}
			>
				<CloseIcon className="text-white" />
			</span>
			<iframe
				autoPlay
				playsInline
				src={props.src}
				width="1140"
				height="614"
				frameBorder="0"
				allow="autoplay; fullscreen"
			></iframe>
		</div>
	);
}

export default ContainerVideo;
