import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import 'date-fns';
import PropTypes from 'prop-types';
import { bothInputProps } from '../utils/inputClasses';

const DateInput = ({
	name = '',
	value = null,
	errors = null,
	onChange = null,
	onBlur = null,
	onAccept = null,
	onClear = null,
	error = true,
	required = true,
	label = '',
	addClass = '',
	helperText = '',
	placeholder = '',
	margin = 'normal',
	variant = 'outlined',
	maxDate = null,
	minDate = new Date('1900-01-01'),
	labelClass = '',
	clearLabel = 'Clear',
	disabled = false
}) => {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
			<KeyboardDatePicker
				margin={margin}
				id={label}
				format="MM/dd/yyyy"
				value={value}
				KeyboardButtonProps={{ 'aria-label': { name } }}
				error={error}
				label={label ? <span className={labelClass}>{label}</span> : null}
				placeholder={placeholder}
				required={required}
				helperText={helperText}
				maxDate={maxDate}
				minDate={minDate}
				inputVariant={variant}
				className={addClass}
				clearLabel={clearLabel}
				disabled={disabled}
				onChange={onChange}
				onBlur={onBlur}
				onClear={onClear}
				onAccept={onAccept}
				{...bothInputProps({ errors, value, type: 'date' })}
			/>
		</MuiPickersUtilsProvider>
	);
};

DateInput.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	errors: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onAccept: PropTypes.func,
	onClear: PropTypes.func,
	error: PropTypes.bool,
	required: PropTypes.bool,
	label: PropTypes.string,
	addClass: PropTypes.string,
	helperText: PropTypes.string,
	placeholder: PropTypes.string,
	margin: PropTypes.string,
	variant: PropTypes.string,
	labelClass: PropTypes.string,
	disabled: PropTypes.bool
};

export default DateInput;
