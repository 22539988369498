import React from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';

const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE_RESUME;

const CellPhoneInput = ({
	countryCode = COUNTRY_CODE.toLocaleLowerCase(),
	label = '',
	value = '',
	onChange = null,
	onBlur = null,
	error = false,
	disabled = false,
	required = true,
	addClass = 'wInputThree'
}) => {
	const containerClasses = () => {
		if (disabled) return 'telInputFull';
		else {
			if (error) return 'telInputErrorFull';
			else return 'telInputFull';
		}
	};

	return (
		<div className={`relative ${addClass}`}>
			{label && (
				<span
					className={`absolute font-normal ${
						error ? 'text-red-400' : 'text-gray-600'
					}`}
					style={{ fontSize: '12px', top: '-20px' }}
				>
					{label}
					{required === true && <span className="text-red-400 ml-1">*</span>}
				</span>
			)}
			<PhoneInput
				inputProps={{ required }}
				country={countryCode}
				value={value}
				onChange={(telNumber, r) => onChange(telNumber, r.countryCode)}
				onBlur={
					onBlur ? (telNumber, r) => onBlur(telNumber, r.countryCode) : null
				}
				isValid={() => (value && !error) || !error}
				containerClass={containerClasses()}
				masks={{
					mx: '(..) ....-....',
					ar: '(..) .....-....'
				}}
				disabled={disabled}
			/>
			{error && (
				<div
					className="text-red-400 p-2"
					style={{ fontSize: '0.75rem', bottom: 0 }}
				>
					{error}
				</div>
			)}
		</div>
	);
};

CellPhoneInput.propTypes = {
	name: PropTypes.string,
	countryCode: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	addClass: PropTypes.string
};

export default CellPhoneInput;
