import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function Captcha(props) {
	return (
		<ReCAPTCHA
			ref={props.recaptchaRef}
			onChange={props.onChange}
			sitekey={process.env.REACT_APP_RECAPTCHA}
		/>
	);
}

export default Captcha;
