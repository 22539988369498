import React from 'react';

// const logo = '/static/images/Asistensi_logotipo.svg';

function SplashScreen() {
	return (
		<div className="splash-screen h-screen w-screen flex justify-center items-center bg-purple-500 z-99 top-0 fixed">
			<style global jsx>
				{`
					.splash-screen .bounce1,
					.splash-screen .bounce2,
					.splash-screen .bounce3 {
						width: 15px;
						height: 15px;
						border-radius: 50%;
						margin: 2px;
						background-color: #fb314a;
						-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
						animation: sk-bouncedelay 1.4s infinite ease-in-out both;
					}

					.splash-screen .bounce1,
					.splash-screen .bounce1 {
						-webkit-animation-delay: -0.32s;
						animation-delay: -0.32s;
					}

					.splash-screen .bounce2,
					.splash-screen .bounce2 {
						-webkit-animation-delay: -0.16s;
						animation-delay: -0.16s;
					}
				`}
			</style>
			<div className="loader-container spinner flex flex-col justify-center items-center">
				{/* <img src={logo} alt="logo asistensi" className="w-auto h-16"/> */}
				<h1 className="text-3xl font-bold text-pink-500">asistensi</h1>
				<div className="flex justify-center items-center mt-4">
					<div className="bounce1"></div>
					<div className="bounce2"></div>
					<div className="bounce3"></div>
				</div>
			</div>
		</div>
	);
}

export default SplashScreen;
