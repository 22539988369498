import React from 'react';
import Link from 'next/link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const BreadcrumbsWidget = ({ routes, is_profile, action }) => {
	return (
		<Breadcrumbs
			separator={<NavigateNextIcon style={{ fontSize: 10 }} />}
			aria-label="breadcrumb"
			className={`mx-auto py-3 ${is_profile === true ? 'w-full' : 'container'}`}
		>
			{routes?.map((route, index) => {
				return routes.length - 1 === index ? (
					<span key={index} className="text-xs font-poppins text-pink-500">
						{route.name}
					</span>
				) : (
					<Link
						key={index}
						href={route?.pathname || '/'}
						as={route?.asPath || '/'}
					>
						{action ? (
							<span
								className="text-xs font-poppins text-gray-600 cursor-pointer"
								onClick={() => action()}
							>
								{route.name}
							</span>
						) : (
							<span className="text-xs font-poppins text-gray-600 cursor-pointer">
								{route.name}
							</span>
						)}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};

export default BreadcrumbsWidget;
